<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-else>
        <v-card-title style="background-color: #7253cf; color: white">
          <div class="d-flex px-1 ml-2">
            <img
              style="margin-top: -15px; margin-bottom: 15px"
              src="@/assets/sponsorModalLogo.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-7 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            color="white"
            large
            style="
              cursor: pointer;
              position: relative;
              right: -18px;
              bottom: 35px;
            "
            @click="toggleSponsorModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-form lazy-validation ref="sponsorForm">
            <v-row no-gutters style="margin-top: 20px">
              <v-col v-if="previewImage" cols="6" class="px-0">
                <img :src="previewImage" alt="" size="100%" class="logo" />
              </v-col>
              <v-col class="file-input-col pt-5" cols="5">
                <div v-if="type == 'add' && !previewImage">
                  <v-btn
                    text
                    dark
                    color="#38227A"
                    class="text-capitalize px-5"
                    style="border: 1px solid #38227a; border-radius: 8px"
                    @click="$refs.inputFile.click()"
                    ><span>upload Logo</span></v-btn
                  >
                </div>
                <div v-if="type == 'edit' || previewImage" style="float: right">
                  <v-btn
                    text
                    dark
                    color="#38227A"
                    class="text-capitalize px-5"
                    style="border: 1px solid #38227a; border-radius: 10px"
                    @click="$refs.inputFile.click()"
                    ><span>change logo</span></v-btn
                  >
                </div>
                <label
                  for="inputId"
                  ref="inputFile"
                  style="display: none"
                ></label>
                <v-file-input
                  accept="image/png,image/jpeg,image/svg+xml"
                  label="upload logo"
                  prepend-icon
                  outlined
                  color="#7253CF"
                  dense
                  class="file-input"
                  id="inputId"
                  @change="onUploadLogoChange"
                  style="display: none"
                  ref="InputFile"
                >
                </v-file-input>
              </v-col>
              <p v-if="!previewImage && type == 'add'" class="logo-des">
                Please upload a image for the sponsor logo,file size should be
                less than 10 MB
              </p>
            </v-row>
            <v-row no-gutters justify="center" class="pt-5">
              <v-col cols="11">
                <v-text-field
                  label="Sponsor name"
                  outlined
                  dense
                  v-model="sponsorName"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  label="Website"
                  outlined
                  dense
                  v-model="website"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  label="Contact person name"
                  outlined
                  dense
                  v-model="contactPersonName"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  label="Contact Person Email"
                  outlined
                  dense
                  v-model="contactPersonEmail"
                  :rules="[rules.required, rules.email]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="11">
                <v-text-field
                  label="Contact Person Phone"
                  outlined
                  dense
                  v-model="contactPersonPhone"
                  type="number"
                  :rules="[rules.required, rules.mobile]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="dialogAction-btnText text-capitalize px-7"
            height="45"
            color="#38227A"
            :loading="formLoading"
            @click="submitForm"
          >
            <span>{{ actionText }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  GAME_API_SPONSOR_POST,
  GAME_API_SPONSOR_UPDATE,
  GAME_API_SPONSOR_DETAIL,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "SponsorModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      previewImage: null,
      imgFile: {},
      sponsorName: "",
      website: "",
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonPhone: null,
    };
  },
  computed: {
    ...mapGetters({
      getShow: "gameManagement/getSponsor",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.toggleSponsorModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.addEditSponsorModal.type;
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add Sponsors Detail";
        case "edit":
          return "Update Sponsor Detail";
        default:
          return "";
      }
    },
    actionText() {
      if (this.type === "add") {
        return "Add Sponsor";
      } else {
        return "Save";
      }
    },
    toastMessage() {
      if (this.type === "add") {
        return "Sponsor Added";
      } else {
        return "Sponsor Updated";
      }
    },
  },
  watch: {
    show(value) {
      if (value) this.openModal();
      else this.closeModal();
    },
  },
  methods: {
    ...mapActions({
      toggleSponsorModal: "gameManagement/toggleSponsor",
      showToast: "snackBar/showToast",
    }),
    openModal() {
        if(this.type==="edit"){
            this.getSponsorDetail()
        }
    },
    closeModal() {
      this.$refs.sponsorForm.reset();
      this.loading = false;
      this.formLoading = false;
      this.previewImage = null;
      this.imgFile = {};
      this.sponsorName = "";
      this.website = "";
      this.contactPersonName = "";
      this.contactPersonEmail = "";
      this.contactPersonPhone = null;
    },
    onUploadLogoChange(payload) {
      // const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file) {
        if (file.size < 1000000) {
          this.imgFile = payload;
          if (file) {
            this.previewImage = URL.createObjectURL(file);
            URL.revokeObjectURL(file); // free memory
          } else {
            this.previewImage = null;
          }
        } else {
          this.showToast({
            message: "Logo file size must be less than 10mb",
            color: "e",
          });
        }
      }
    },
    getSponsorDetail() {
        this.loading = true
      const successHandler = (res) => {
          this.sponsorName = res.data.sponsor_detail.sponsor_name
          this.previewImage = res.data.sponsor_detail.sponsor_logo
          this.website = res.data.sponsor_detail.website
          this.contactPersonName = res.data.sponsor_detail.contact_person_name
          this.contactPersonEmail = res.data.sponsor_detail.contact_person_email
          this.contactPersonPhone = res.data.sponsor_detail.contact_Person_phone
        this.loading = false
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false
      };
      let formData = {};
      formData["sponsor_id"] =
        this.$store.state.gameManagement.addEditSponsorModal.sponsorID;
      Axios.request_GET(
        GAME_API_SPONSOR_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      const self = this;
      if (this.$refs.sponsorForm.validate()) {
          self.formLoading = true
        const successHandler = (res) => {
          console.log(res.data);
          self.$emit("reload");
          self.toggleSponsorModal({ show: false });
          self.showToast({
            message: this.toastMessage,
            color: "s",
          });
          self.formLoading = false
        };
        const failureHandler = (res) => {
          self.showToast({
            message: res,
            color: "e",
          });
          self.formLoading = false
        };
        let formData = new FormData();
        formData.append("sponsor_name", self.sponsorName);
        formData.append("contact_person_name", self.contactPersonName);
        formData.append("contact_person_email", self.contactPersonEmail);
        formData.append("contact_Person_phone", self.contactPersonPhone);
        formData.append("status", true);
        formData.append("website", self.website);
        formData.append("game", self.$route.query.game_id);
        if(self.imgFile instanceof File){
            formData.append("sponsor_logo", self.imgFile);
        }
        if (self.type === "add") {
          Axios.request_POST(
            GAME_API_SPONSOR_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          formData.append(
            "id",
            self.$store.state.gameManagement.addEditSponsorModal.sponsorID
          );
          Axios.request_PATCH(
            GAME_API_SPONSOR_UPDATE,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 20px;
}
img.logo {
  margin-left: 20px;
  max-height: 80px;
}
.file-input-col {
  margin-left: 20px;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
